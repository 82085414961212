<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司名称" prop="name">
							<el-input v-model="formData.name" placeholder="请输入公司名称" disabled />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row justify="center" align="center">
					<table cellspacing="0" class="settlement-table">
						<tr>
							<th>结算项</th>
							<th>结算值</th>
						</tr>
						<tr v-for="config in configParams" :key="config.type">
							<td>{{ config.type === 10 ? '违约金比例' : '未知选项' }}</td>
							<td>
								<el-input-number
									v-model="config.configValue"
									step="0.01"
									min="0"
									max="1"
									step-strictly
									:controls="false"
									placeholder="请输入0-1之间数字"
								/>
							</td>
						</tr>
					</table>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="isLoading" type="primary" :disabled="isDisabled" @click="onSubmit">
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])
const dialogTitle = ref('')

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)

const formInitValue = {
	shortName: '',
	name: '',
	status: '',
}

const formData = reactive({ ...formInitValue })
const formRule = rules

const configParams = ref([])

let formType = 'create'
let waitUpdateId = ''

function initEnums() {
	/* Request({
		url: 'suf4-user-service/company/select?status=1',
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				console.log('枚举获取成功 ==>', res.data)
				companyOptions.value = res.data
			} else {
				$message.error(res.msg)
			}
		})
		.catch(error => {
			$message.error(error.message)
		}) */
}

function getSettlementById(id) {
	// /suf4-user-service/companyConfig/listByCompanyId
	isLoading.value = true
	Request({
		url: 'suf4-user-service/companyConfig/listByCompanyId',
		method: 'POST',
		params: {
			companyId: id,
		},
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('获取结算配置成功 ==>', res.data)
				if (res.data.length > 0) {
					configParams.value = res.data
					formType = 'update'
				} else {
					configParams.value = [
						{
							companyId: waitUpdateId,
							configValue: '',
							type: 10,
						},
					]
					formType = 'create'
				}
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create(item) {
	formType = 'create'
	dialogTitle.value = '编辑结算配置'
	waitUpdateId = item.id
	Object.assign(formData, item)
	initEnums()
	getSettlementById(item.id)
	isShowDialog.value = true
}

function update(item) {
	formType = 'update'
	initEnums()
	dialogTitle.value = '编辑子公司'
	waitUpdateId = item.id
	Object.assign(formData, item)
	initEnums()
	isShowDialog.value = true
}

function deleteItem() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

async function onSubmit() {
	try {
		isLoading.value = true
		console.log('表单校验成功 ==>', configParams.value)
		configParams.value.forEach(item => {
			if (!item.configValue && item.configValue !== 0) {
				throw new Error('请填写>=0且<=1的数值!')
			}
		})
		let requestUrl = ''
		const params = {
			companyId: configParams.value[0].companyId,
			configValue: configParams.value[0].configValue,
			type: configParams.value[0].type,
		}
		if (formType === 'create') {
			requestUrl = `suf4-user-service/companyConfig/create`
		} else {
			requestUrl = `suf4-user-service/companyConfig/update`
			params.id = configParams.value[0].id
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	configParams.value = []
}

defineExpose({ create, update, deleteItem })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}

.settlement-table {
	border: 1px solid #eeeeee;
	border-collapse: collapse;

	th {
		border: 1px solid #eeeeee;
		padding: 12px 40px;
	}
	td {
		border: 1px solid #eeeeee;
		padding: 12px 40px;
	}
}
</style>
