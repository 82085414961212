export const columns = [
	{
		prop: 'name',
		label: '公司名称',
	},
	{
		prop: 'shortName',
		label: '公司简称',
	},
	{
		prop: 'status',
		label: '公司状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 1 ? '启用' : '禁用'
		},
	},
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '公司名称',
			attr: 'name',
			type: 'search',
			placeholder: '请输入公司名称',
			clearable: true,
		},
		{
			attr: 'status',
			label: '状态',
			type: 'select',
			placeholder: '请选择状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
	shortName: [{ required: true, message: '请输入公司简称', trigger: 'blur' }],
	cityList: [{ required: true, message: '请选择运营城市', trigger: 'blur' }],
	status: [{ required: true, message: '请选择公司状态', trigger: 'blur' }],
	useContractProduct: [{ required: true, message: '请选择合同商品方案', trigger: 'blur' }],
	address: [
		{
			required: true,
			message: '请填写公司联系地址',
			trigger: 'blur',
		},
	],
	useESign: [
		{
			required: true,
			message: '请选择电子合同',
			trigger: 'change',
		},
	],
	signCompanyId: [
		{
			required: true,
			message: '请选择合同签约主体',
			trigger: 'change',
		},
	],
	assignedSealId: [
		{
			required: false,
			message: '请输入电子印章ID',
			trigger: 'blur',
		},
	],
}
