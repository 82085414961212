<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司名称" prop="name">
							<el-input
								v-model="formData.name"
								placeholder="请输入公司名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司简称" prop="shortName">
							<el-input
								v-model="formData.shortName"
								placeholder="请输入公司简称"
								maxlength="20"
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="运营城市" prop="cityList">
							<el-cascader
								v-model="formData.cityList"
								style="width: 100%"
								:props="cityCascadeProps"
								:options="cityOptions"
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司联系地址" prop="address">
							<el-input
								type="textarea"
								v-model="formData.address"
								:maxlength="40"
								:autosize="{
									minRows: 3,
								}"
								show-word-limit
								style="width: 100%"
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="合同商品方案" prop="useContractProduct">
							<el-radio-group v-model="formData.useContractProduct" :disabled="isDisabled">
								<el-radio :label="true">使用</el-radio>
								<el-radio :label="false">不使用</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="电子合同" prop="useESign">
							<el-radio-group v-model="formData.useESign" :disabled="isDisabled">
								<el-radio :label="true">使用</el-radio>
								<el-radio :label="false">不使用</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="电子印章ID" prop="assignedSealId" :required="formData.useESign">
							<el-input v-model="formData.assignedSealId" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="合同签约主体" prop="signCompanyId">
							<el-select v-model="formData.signCompanyId" :disabled="isDisabled">
								<el-option
									v-for="(item, index) in contractSignings"
									:key="index"
									:label="item.companyName"
									:value="item.companyId"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="公司状态" prop="status">
							<el-radio-group v-model="formData.status" :disabled="isDisabled">
								<el-radio v-for="(item, index) in statusOptions" :key="index" :label="item.code">
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="isLoading" type="primary" :disabled="isDisabled" @click="onSubmit">
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits, watch } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])
const dialogTitle = ref('')

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)

const cityCascadeProps = {
	multiple: true,
	value: 'code',
	label: 'name',
	emitPath: false,
	checkStrictly: true,
	children: 'cChildren',
}

const formInitValue = {
	shortName: '',
	name: '',
	status: '',
	cityList: [],
	useContractProduct: '',
	address: '',
	useESign: '',
	assignedSealId: '',
	signCompanyId: '',
}

const formData = reactive({ ...formInitValue })
const formRule = rules
const cityOptions = ref([])
const statusOptions = [
	{ name: '启用', code: 1 },
	{ name: '禁用', code: 0 },
]
const contractSignings = ref([])

let formType = 'create'
let waitUpdateId = ''

watch(
	() => formData.cityList,
	val => {
		console.log('get val ==>', val)
		getSigningCompanys(val)
	},
)

watch(
	() => formData.useESign,
	val => {
		if (!val) {
			formData.assignedSealId = ''
		}
	},
)

function initEnums() {
	Request({
		url: 'suf4-system-service/region/tree',
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				console.log('城市列表获取成功 ==>', res.data)
				cityOptions.value = res.data.map(item => {
					return {
						code: item.code,
						name: item.name,
						cChildren: item.children,
						disabled: true,
					}
				})
			} else {
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	formType = 'create'
	dialogTitle.value = '新增子公司'
	initEnums()
	isShowDialog.value = true
}

function update(item) {
	formType = 'update'
	initEnums()
	dialogTitle.value = '编辑子公司'
	waitUpdateId = item.id
	Object.assign(formData, item)
	if (item.city) {
		formData.cityList = item.city.split(',').map(code => parseInt(code))
	}
	isShowDialog.value = true
}

function deleteItem() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = formData
		if (formType === 'create') {
			requestUrl = `suf4-user-service/company/create`
			delete params.id
		} else {
			requestUrl = `suf4-user-service/company/update`
			params.id = waitUpdateId
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	Object.assign(formData, formInitValue)
}

async function getSigningCompanys(val) {
	let cityList = val.join(',')
	if (!val.length) return
	try {
		const { code, data, msg } = await Request({
			url: `/suf4-user-service/company/select/by/city/code?cityCode=${cityList}`,
			method: 'GET',
		})
		if (code === 200) {
			contractSignings.value = data || []
		} else {
			throw new Error(msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

defineExpose({ create, update, deleteItem })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
